//URL base de la api de la cual se optienen los departamentos y sus municipios
export const API_HOST: string = 'https://api.npoint.io/253f0ee259ef1620a547';

//URL base del ArcGis serve del MARN
export const API_MARN: string = 'https://geoportal.snet.gob.sv/server/rest/services/sig_ccanales/CapasVIGEA2022/MapServer';

//URL base de drive donde están subidos los decretos y otros documentos
export const DRIVE_DOCS: string = 'https://drive.google.com/drive/folders/';

/*
    URL's de cada capa obtenida de la REST API del MARN:
    Se agregan a un array llamado 'layers' y este se exporta para ser utilizado por ./Components/Map.js
*/
//Array de enlaces de Layers
const layers = [];

/*
    Antiguo Cuscatlan     
*/
layers.push(API_MARN + '/145');  //Pasos de fauna
layers.push(API_MARN + '/146');  //Proyectos con permiso ambiental
layers.push(API_MARN + '/147');  //Corredores AC unión
layers.push(API_MARN + '/148');  //Zonas ambientales

/*
    VSS
*/
layers.push(API_MARN + '/150');  //Amortiguamiento2
layers.push(API_MARN + '/151');  //Zonas de amortiguamiento
layers.push(API_MARN + '/152');  //Zonas VSS

/*
    Nuevo Cuscatlán
*/ 
layers.push(API_MARN + '/153');  //Zonificación

/* 
    Nejapa
*/
layers.push(API_MARN + '/155');   //Amoriguamiento2
layers.push(API_MARN + '/156');   //Amortiguamiento1
layers.push(API_MARN + '/157');   //Plan desarrollo logistico
layers.push(API_MARN + '/158');   //Zona de retiro
layers.push(API_MARN + '/159');   //Proyectos con permiso
layers.push(API_MARN + '/160');   //ZOnas

/*
    Cordillera del Balsamo
*/
layers.push(API_MARN + '/162');    //Zona de amortiguamiento
layers.push(API_MARN + '/163');    //Las colinas
layers.push(API_MARN + '/164');    //Lindero de proyectos
layers.push(API_MARN + '/165');    //Zonas

/*
    Santo Tomás
*/
layers.push(API_MARN + '/167');   //Zonificación

/*
    Zona Nor Oriental
*/
layers.push(API_MARN + '/169');  //Agua
layers.push(API_MARN + '/170');  //Zonificación
layers.push(API_MARN + '/171');  //Dem. Limit.

/*
    Zona Nor Occidental
*/
layers.push(API_MARN + '/173');  //Dem. Limit.
layers.push(API_MARN + '/174');  //Reserva
layers.push(API_MARN + '/175');  //Ramsar
layers.push(API_MARN + '/176');  //Distri. Riesgo
layers.push(API_MARN + '/177');  //Cuenca
layers.push(API_MARN + '/178');  //Agua
layers.push(API_MARN + '/179');  //ZAUS

/*
    Coatepeque
*/
layers.push(API_MARN + '/181');   //FUNDACOATEPEQUE
layers.push(API_MARN + '/182');   //Ameneza Volcanica
layers.push(API_MARN + '/183');   //Biosfera
layers.push(API_MARN + '/184');   //ZONAS

/*
    FCM
*/
layers.push(API_MARN + '/186');  //RB
layers.push(API_MARN + '/187');  //SRamsar
layers.push(API_MARN + '/188');  //Agua
layers.push(API_MARN + '/189');  //DemarcLimit
layers.push(API_MARN + '/190');  //Rellenos
layers.push(API_MARN + '/191');  //Pedreras
layers.push(API_MARN + '/192');  //Zonas
layers.push(API_MARN + '/193');  //ResAcuat
layers.push(API_MARN + '/194');  //PescaArt
layers.push(API_MARN + '/195');  //Los Cóbanos

/*
    Golfo de Fonseca 
 */
layers.push(API_MARN + '/198'); //Corre. bio. prio.
layers.push(API_MARN + '/199'); //corre. bio.
layers.push(API_MARN + '/200'); //Área marina
layers.push(API_MARN + '/201'); //Área reserva
layers.push(API_MARN + '/202'); //Área pesca
layers.push(API_MARN + '/203'); //Batimetría
layers.push(API_MARN + '/204'); //MSPA
layers.push(API_MARN + '/205'); //ZAUS

export default layers;