import NavigationIcon from '@mui/icons-material/Navigation';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const NButtom = () => {
  const navigate = useNavigate();
  return (
    <Button variant="outlined" size='large' onClick={() =>
      navigate('/mapa')
    } startIcon={<NavigationIcon />} sx={{
      color: '#FFFF',
      ":hover": '#6c757d',
      width: 'fit-content',
      marginLeft: '4rem',
      marginBottom: '4rem', 
      fontFamily: "'Relaway', sans-serif", 
      fontWeight: '300', 
    }} >
      Mapa
    </Button>
  );
}

export default NButtom;