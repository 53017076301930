import { Autocomplete, CircularProgress, Container, FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getMunicipios } from '../api/municipios';
import { map } from "lodash";
import { Municipio, Departamento } from "./Municipio";
import { Box } from "@mui/system";
import NavigateButtom from '../components/NavigateButtom';
import { useNavigate } from 'react-router-dom';
import './style/Form.css';

const Form = () => {
    const [departamentos, setDepartamentos] = useState<Departamento[] | any[]>([]);
    const [municipios, setMunicipios] = useState<Municipio[] | any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    //const [selectMuni, setSelectMuni] = useState('');
    const [lock, setLock] = useState<boolean>(true);
    //const [selectDepto, setSelectDepto] = useState<Departamento | null>();
    const navigate = useNavigate();


    useEffect(() => {
        getMunicipios().then((response) => {
            map(response, (depto: Departamento, index) => {
                setDepartamentos((departamentos: Departamento[]) => {
                    return departamentos.concat(depto);
                });
            });
        });
        setLoading(false);
    }, []);

    return (
        <FormControl fullWidth>
            <Container maxWidth="lg" sx={{ background: '#3c4557' }}>
                <div className="textForm" id="firstDiv">{`Comienza seleccionando un departamento y municipio:`}</div>
                {loading ? (
                    <>
                        <CircularProgress disableShrink />
                    </>
                ) : (
                    <Box sx={{
                        display: 'flex',
                        'flex-direction': 'row',
                        marginLeft: '4rem',
                    }}>
                        <Autocomplete
                            id="combo-box-deptos"
                            options={departamentos}
                            onChange={(event: any, newValue: Departamento | any) => {
                                setMunicipios(newValue.municipios);
                                //console.log(newValue.nombre);
                                //setSelectDepto(newValue.nombre);
                                setLock(false);
                            }}
                            sx={{ width: 200, marginBottom: '1rem', fontFamily: "'Relaway', sans-serif", fontWeight: '300', }}
                            getOptionLabel={(option: Departamento) => {
                                //console.log('option.nombre:', option.nombre);
                                return option.nombre.toString() || " ";
                            }}
                            renderInput={(params) => <TextField {...params} label="Departamentos" sx={{fontFamily: "'Relaway', sans-serif", fontWeight: '300',}} />}

                        />
                        <Autocomplete
                            disabled={lock}
                            id="combo-box-munis"
                            options={municipios}
                            //inputValue={selectMuni}
                            onChange={(event: any, newInputValue: Municipio | any) => {
                                //window.open(window.location.origin+'/mapa/'+ selectDepto + '/' + newInputValue.nombre, '_self');
                                navigate('/mapa/' + newInputValue.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                            }}
                            sx={{ width: 200, marginLeft: '2rem', fontFamily: "'Relaway', sans-serif", fontWeight: '300', }}
                            getOptionLabel={(option: Municipio) => {
                                //console.log(option);
                                return option.nombre.toString() || " ";
                            }}
                            renderInput={(params) => <TextField {...params} label="Municipios" sx={{fontFamily: "'Relaway', sans-serif", fontWeight: '300',}} />}
                        />

                    </Box>
                )}
                <div className="textForm">{`Ingresa directamente al mapa:`}</div>
                <NavigateButtom />
            </Container>
        </FormControl>
    );
};

export default Form;