import { AppBar, Container, Toolbar, Typography } from "@mui/material";
import logoMarn from '../assets/imgs/LOGOMARN-B.png';
import '../assets/style/Navbar.css';

const NavBar = () => {
    return (
        <AppBar position="static" sx={{ background: 'inherit', marginTop: '5%', }}>
            <Container maxWidth="lg" className='appBar' sx={{ background: '#29303C', }}>
                <Toolbar sx={{
                    justifyContent: 'space-between'
                }}>
                    <img alt="Logo del MARN" src={logoMarn} className='logo'/>
                    <Typography
                        variant="h6"
                        noWrap={false}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', sm: 'block' },
                            fontFamily: "'Relaway', sans-serif",
                            fontWeight: '500',
                            fontSize: '1.35rem',
                            letterSpacing: '.05rem',
                            textAlign: 'left',
                            background: 'inherit',
                            flexGrow: 1,
                        }}
                    >
                        Herramienta Geoespacial Para la Gestión Ambiental y Planificación Territorial
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;