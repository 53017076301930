import { useRef, useEffect } from 'react';
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import config from "@arcgis/core/config";
import layers from '../constants/URL';
import { API_MARN } from '../constants/URL';
import Home from '@arcgis/core/widgets/Home';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import LayerList from "@arcgis/core/widgets/LayerList";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import Slider from "@arcgis/core/widgets/Slider";
import Search from "@arcgis/core/widgets/Search";
import { useParams } from 'react-router';
import { popupAC, popupVSS, popupNC, popupNe, popupCord, popupSTo, popupOr, popupOcc, popupCo, popupF, popupGol } from '../constants/PopupTemplates';
import Expand from "@arcgis/core/widgets/Expand";
import Legend from "@arcgis/core/widgets/Legend";
import { DRIVE_DOCS } from '../constants/URL';

function Map() {
  const mapDiv = useRef(null);
  let { municipio } = useParams();

  useEffect(() => {

    if (mapDiv.current) {

      config.apiKey = "AAPK39de757bf6af4de0bc1bda590f1e9cea9zSpKtCQyMCgD_VYhRteL90IUF6I6d8gLVRe_cuiNpTNNcNFI6S93o6V1k--lrMd";

      /**
       * Creación de todas las Feature Layers de El Salvador
       */
      //Ant Cusc.
      const faunaAC = new FeatureLayer({
        url: layers[0],
        title: 'Pasos de fauna',
        visible: false,
        popupTemplate: popupAC
      });
      const proyAC = new FeatureLayer({
        url: layers[1],
        title: 'Proyectos con permiso ambiental',
        visible: false,
        popupTemplate: popupAC
      });
      const corredoresAC = new FeatureLayer({
        url: layers[2],
        title: 'Corredores AC unión',
        visible: false,
        popupTemplate: popupAC
      });
      const zonasAC = new FeatureLayer({
        url: layers[3],
        title: 'Zonas ambientales',
        outFields: ["*"],
        popupTemplate: popupAC
      });
      //VSS
      const vAmort2 = new FeatureLayer({
        url: layers[4],
        title: 'Zonas de Amortiguamiento 2',
        visible: false,
        popupTemplate: popupVSS
      });
      const vAmort = new FeatureLayer({
        url: layers[5],
        title: 'Zonas de Amortiguamiento',
        visible: false,
        popupTemplate: popupVSS
      });
      const vZonas = new FeatureLayer({
        url: layers[6],
        title: 'Zonas del Volcan',
        popupTemplate: popupVSS
      });
      //Nuev. Cusc
      const nCZonas = new FeatureLayer({
        url: layers[7],
        title: 'Zonas de nuevo Cuscatlan',
        popupTemplate: popupNC
      });
      //Nejapa
      const neAmort2 = new FeatureLayer({
        url: layers[8],
        title: 'Zonas de amortiguamiento2',
        popupTemplate: popupNe
      });
      const neAmort = new FeatureLayer({
        url: layers[9],
        title: 'Zonas de amortiguamiento',
        popupTemplate: popupNe
      });
      const neDes = new FeatureLayer({
        url: layers[10],
        title: 'Plan de desarrollo',
        popupTemplate: popupNe
      });
      const neReti = new FeatureLayer({
        url: layers[11],
        title: 'Zona de retiro',
        popupTemplate: popupNe
      });
      const nePro = new FeatureLayer({
        url: layers[12],
        title: 'Proyectos con permiso',
        popupTemplate: popupNe
      });
      const neZonas = new FeatureLayer({
        url: layers[13],
        title: 'Zonas de Nejapa',
        popupTemplate: popupNe
      });
      //Zonas de la Cordillera del Balsamo
      const cordAmort = new FeatureLayer({
        url: layers[14],
        title: 'Zona de amortiguamiento',
        popupTemplate: popupCord
      });
      const cordCol = new FeatureLayer({
        url: layers[15],
        title: 'Colinas',
        popupTemplate: popupCord
      });
      const cordPro = new FeatureLayer({
        url: layers[16],
        title: 'Lindero de proyectos',
        popupTemplate: popupCord
      });
      const cordZonas = new FeatureLayer({
        url: layers[17],
        title: 'Zonas de la Cordillera del Balsamo',
        popupTemplate: popupCord
      });
      //Santo Tomás
      const sToZonas = new FeatureLayer({
        url: layers[18],
        title: 'Zonas de Santo Tomás',
        popupTemplate: popupSTo
      });
      //Zona Nor Oriental
      const orAgua = new FeatureLayer({
        url: layers[19],
        title: 'Cuerpos de agua',
        popupTemplate: popupOr
      });
      const orZonas = new FeatureLayer({
        url: layers[20],
        title: 'Zona Nor Oriental',
        popupTemplate: popupOr
      });
      const orLim = new FeatureLayer({
        url: layers[21],
        title: 'Dem. Limit.',
        popupTemplate: popupOr
      });
      //Zona Nor Occidental
      const ocLim = new FeatureLayer({
        url: layers[22],
        title: 'Dem. Lit',
        popupTemplate: popupOcc
      });
      const ocRe = new FeatureLayer({
        url: layers[23],
        title: 'Reserva',
        popupTemplate: popupOcc
      });
      const ocRam = new FeatureLayer({
        url: layers[24],
        title: 'Ramsar',
        popupTemplate: popupOcc
      });
      const ocDis = new FeatureLayer({
        url: layers[25],
        title: 'Distribución de Riesgo',
        popupTemplate: popupOcc
      });
      const ocCuenc = new FeatureLayer({
        url: layers[26],
        title: 'Cuenca',
        popupTemplate: popupOcc
      });
      const ocAgua = new FeatureLayer({
        url: layers[27],
        title: 'Cuerpos de agua',
        popupTemplate: popupOcc
      });
      const ocZa = new FeatureLayer({
        url: layers[28],
        title: 'ZAUS',
        popupTemplate: popupOcc
      });
      //Coatepeque
      const coFunda = new FeatureLayer({
        url: layers[29],
        title: 'FUNDACOATEPEQUE',
        popupTemplate: popupCo
      });
      const coAVolc = new FeatureLayer({
        url: layers[30],
        title: 'Amenaza Volcánica',
        popupTemplate: popupCo
      });
      const coBio = new FeatureLayer({
        url: layers[31],
        title: 'Biosfera',
        popupTemplate: popupCo
      });
      const coZonas = new FeatureLayer({
        url: layers[32],
        title: 'Zonificación de Coatepeque',
        popupTemplate: popupCo
      });
      //FCM
      const fRB = new FeatureLayer({
        url: layers[33],
        title: 'RB',
        popupTemplate: popupF
      });
      const fRam = new FeatureLayer({
        url: layers[34],
        title: 'SRamsar',
        popupTemplate: popupF
      });
      const fAgua = new FeatureLayer({
        url: layers[35],
        title: 'Cuerpos de agua',
        popupTemplate: popupF
      });
      const fDemar = new FeatureLayer({
        url: layers[36],
        title: 'Demarc. Limit.',
        popupTemplate: popupF
      });
      const fRe = new FeatureLayer({
        url: layers[37],
        title: 'Rellenos',
        popupTemplate: popupF
      });
      const fPe = new FeatureLayer({
        url: layers[38],
        title: 'Pedreras',
        popupTemplate: popupF
      });
      const fZonas = new FeatureLayer({
        url: layers[39],
        title: 'Zonas Franja Costero Marina',
        popupTemplate: popupF
      });
      const fAcuat = new FeatureLayer({
        url: layers[40],
        title: 'Res. Acuat.',
        popupTemplate: popupF
      });
      const fPesca = new FeatureLayer({
        url: layers[41],
        title: 'Pesca Art.',
        popupTemplate: popupF
      });
      const fCo = new FeatureLayer({
        url: layers[42],
        title: 'Los Cóbanos',
        popupTemplate: popupF
      });
      //Golfo de Fonseca
      const golPrio = new FeatureLayer({
        url: layers[43],
        title: 'Corredores biológicos prioritarios',
        popupTemplate: popupGol
      });
      const golCorre = new FeatureLayer({
        url: layers[44],
        title: 'Corredores biológicos',
        popupTemplate: popupGol
      });
      const golMar = new FeatureLayer({
        url: layers[45],
        title: 'Área marina',
        popupTemplate: popupGol
      });
      const golRes = new FeatureLayer({
        url: layers[46],
        title: 'Área reserva',
        popupTemplate: popupGol
      });
      const golPes = new FeatureLayer({
        url: layers[47],
        title: 'Área pesca',
        popupTemplate: popupGol
      });
      const golBati = new FeatureLayer({
        url: layers[48],
        title: 'Batimetría',
        popupTemplate: popupGol
      });
      const golMSPA = new FeatureLayer({
        url: layers[49],
        title: 'MSPA',
        popupTemplate: popupGol
      });
      const golZAUS = new FeatureLayer({
        url: layers[50],
        title: 'ZAUS',
        popupTemplate: popupGol
      });

      /**
       * Declaración de todas las group Layers
       */
      const gLAC = new GroupLayer({
        title: "Zonificación Antiguo Cuscatlán",
        visible: false,
        visibilityMode: "independent",
        layers: [faunaAC, proyAC, corredoresAC, zonasAC],
        opacity: 0.75,

      });
      const gLV = new GroupLayer({
        title: 'Zonificación Volcán de San Salvador',
        visible: false,
        visibilityMode: 'independent',
        layers: [vAmort, vAmort2, vZonas],
        opacity: 0.75
      });
      const gLNC = new GroupLayer({
        title: 'Zonificación Nuevo Cuscatlan',
        visible: false,
        visibilityMode: 'independent',
        layers: [nCZonas],
        opacity: 0.75
      });
      const gLNe = new GroupLayer({
        title: 'Zonificación Nejapa',
        visible: false,
        visibilityMode: 'independent',
        layers: [neAmort, neAmort2, neDes, neReti, nePro, neZonas],
        opacity: 0.75
      });
      const gLCord = new GroupLayer({
        title: 'Zonificación Cordillera del Balsamo',
        visible: false,
        visibilityMode: 'independent',
        layers: [cordAmort, cordCol, cordPro, cordZonas],
        opacity: 0.75
      });
      const gLSTo = new GroupLayer({
        title: 'Zonificación Santo Tomás',
        visible: false,
        visibilityMode: 'independent',
        layers: [sToZonas],
        opacity: 0.75
      });
      const gLNO = new GroupLayer({
        title: 'Zonificación Nor Oriental',
        visible: false,
        visibilityMode: 'independent',
        layers: [orAgua, orZonas, orLim],
        opacity: 0.75
      });
      const gLNOc = new GroupLayer({
        title: 'Zonificación Nor Occidental',
        visible: false,
        visibilityMode: 'independent',
        layers: [ocLim, ocRe, ocRam, ocDis, ocCuenc, ocAgua, ocZa],
        opacity: 0.75
      });
      const gLCoa = new GroupLayer({
        title: 'Zonificación de Coatepeque',
        visible: false,
        visibilityMode: 'independent',
        layers: [coFunda, coAVolc, coBio, coZonas],
        opacity: 0.75
      });
      const gLFCM = new GroupLayer({
        title: 'Zonificación Franja Costero Marina',
        visible: false,
        visibilityMode: 'independent',
        layers: [fRB, fRam, fAgua, fDemar, fRe, fPe, fZonas, fAcuat, fPesca, fCo],
        opacity: 0.75
      });
      const gLGolfo = new GroupLayer({
        title: 'Zonificación Golfo de Fonseca',
        visible: false,
        visibilityMode: 'independent',
        layers: [golPrio, golCorre, golMar, golRes, golPes, golBati, golMSPA, golZAUS],
        opacity: 0.75
      });

      /**
       * Initialize applicationx
       */
      const webmap = new WebMap({
        basemap: "arcgis-topographic"
      });

      const view = new MapView({
        container: mapDiv.current,
        map: webmap,
        center: [-88.89653, 13.794185],
        zoom: 8
      });

      /**
       * Adición de todas las Group Layers al Web Map
       */
      webmap.layers.add(gLAC);
      webmap.layers.add(gLV);
      webmap.layers.add(gLNC);
      webmap.layers.add(gLNe);
      webmap.layers.add(gLCord);
      webmap.layers.add(gLSTo);
      webmap.layers.add(gLNO);
      webmap.layers.add(gLNOc);
      webmap.layers.add(gLCoa);
      webmap.layers.add(gLFCM);
      webmap.layers.add(gLGolfo);

      //Expresión regular del titulo de cada Group Layer
      const regExp = new RegExp('^Zonificación *');



      function defineActions(event) {
        // The event object contains an item property.
        // is is a ListItem referencing the associated layer
        // and other properties. You can control the visibility of the
        // item, its title, and actions using this object.

        const item = event.item;

        if (regExp.test(item.title) === true) {
          // An array of objects defining actions to place in the LayerList.
          // By making this array two-dimensional, you can separate similar
          // actions into separate groups with a breaking line.
          item.actionsSections = [
            [
              {
                title: "Ampliar capa",
                className: "esri-icon-zoom-out-fixed",
                id: "full-extent"
              },
              {
                title: "Más información",
                className: "esri-icon-description",
                id: "information"
              }
            ],
            [
              {
                title: "Aumentar opacidad",
                className: "esri-icon-up",
                id: "increase-opacity"
              },
              {
                title: "Disminuir opacidad",
                className: "esri-icon-down",
                id: "decrease-opacity"
              }
            ]
          ];
        }

        // Adds a slider for updating a group layer's opacity
        if (item.children.length === 0 && item.parent) {
          const slider = new Slider({
            min: 0,
            max: 1,
            precision: 2,
            values: [1],
            visibleElements: {
              labels: true,
              rangeLabels: true
            }
          });

          item.panel = {
            content: slider,
            className: "esri-icon-sliders-horizontal",
            title: "Cambiar opacidad de la capa"
          };

          slider.on("thumb-drag", (event) => {
            const { value } = event;
            item.layer.opacity = value;
          });
        }
      }

      const featureLayerMunicipios = new FeatureLayer({
        url: API_MARN + '/30',
        popupTemplate: {
          // autocasts as new PopupTemplate()
          title: "{MUNIC}",
          overwriteActions: true
        }
      });

      const featureLayerDepartamentos = new FeatureLayer({
        url: API_MARN + '/29',
        popupTemplate: {
          // autocasts as new PopupTemplate()
          title: "{DPTO}",
          overwriteActions: true
        }
      });

      const searchWidget = new Search({
        view: view,
        allPlaceholder: "Departamento o municipio",
        includeDefaultSources: false,
        sources: [
          {
            layer: featureLayerMunicipios,
            searchFields: ["MUNIC"],
            displayField: "MUNIC",
            exactMatch: false,
            outFields: ["MUNIC", "NOM_DPTO"],
            name: "Municipios",
            placeholder: "example: Nejapa",
            resultSymbol: {
              type: "simple-fill",
              color: [255, 255, 159, 0.5],
              outline: {
                color: "red",
                width: .5
              }
            }
          },
          {
            layer: featureLayerDepartamentos,
            searchFields: ["DPTO"],
            exactMatch: false,
            outFields: ["*"],
            placeholder: "example: San Salvador",
            name: "Departamentos",
            resultSymbol: {
              type: "simple-fill",
              color: [255, 255, 159, 0.5],
              outline: {
                color: "red",
                width: .5
              }
            }
          }
        ]
      });

      //Leyendas
      const legend = new Expand({
        view: view,
        expandIconClass: "esri-icon-legend",
        expandTooltip: "Leyenda",
        label: "Leyenda",
        content: new Legend({
          view: view,
          layerInfos: [
            {
              layer: faunaAC,
              title: "Pasos Fauna"
            },
            {
              layer: proyAC,
              title: "Proyectos con permiso ambiental"
            },
            {
              layer: corredoresAC,
              title: "Corredores AC unión"
            },
            {
              layer: zonasAC,
              title: "Zonas Ambientales"
            },
            {
              layer: vAmort,
              title: "Zonas de Amortiguamiento"
            },
            {
              layer: vAmort2,
              title: "Zonas de Amortiguamiento 2"
            },
            {
              layer: vZonas,
              title: "Zonas del Volcán"
            },
            {
              layer: nCZonas,
              title: "Zonas de Nuevo Cuscatlán"
            },
            {
              layer: neAmort2,
              title: "Zonas de Amortiguamiento 2"
            },
            {
              layer: neAmort,
              title: "Zonas de Amortiguamiento"
            },
            {
              layer: neDes,
              title: "Plan de Desarrollo"
            },
            {
              layer: neReti,
              title: "Zonas de Retiro"
            },
            {
              layer: nePro,
              title: "Proyectos con permiso"
            },
            {
              layer: neZonas,
              title: "Zonas de Nejapa"
            },
            {
              layer: cordAmort,
              title: "Zonas de Amortiguamiento"
            },
            {
              layer: cordCol,
              title: "Colinas"
            },
            {
              layer: cordPro,
              title: "Lindero de proyectos"
            },
            {
              layer: cordZonas,
              title: "Zonas de la Cordillera del Bálsamo"
            },
            {
              layer: sToZonas,
              title: "Zonas de Santo Tomás"
            },
            {
              layer: orAgua,
              title: "Cuerpos de Agua"
            },
            {
              layer: orZonas,
              title: "Zonas Nor Oriental"
            },
            {
              layer: orLim,
              title: "Dem. Limit"
            },
            {
              layer: ocLim,
              title: "Dem. Lit"
            },
            {
              layer: ocRe,
              title: "Reserva"
            },
            {
              layer: ocRam,
              title: "Ramsar"
            },
            {
              layer: ocDis,
              title: "Distribución de Riesgo"
            },
            {
              layer: ocCuenc,
              title: "Cuenca"
            },
            {
              layer: ocAgua,
              title: "Cuerpos de agua"
            },
            {
              layer: ocZa,
              title: "ZAUS"
            },
            {
              layer: coFunda,
              title: "FUNDACOATEPEQUE"
            },
            {
              layer: coAVolc,
              title: "Amenaza Volcánica"
            },
            {
              layer: coBio,
              title: "Biosfera"
            },
            {
              layer: coZonas,
              title: "Zonificación de Coatepeque"
            },
            {
              layer: fRB,
              title: "RB"
            },
            {
              layer: fRam,
              title: "SRamsar"
            },
            {
              layer: fAgua,
              title: "Cuerpos de Agua"
            },
            {
              layer: fDemar,
              title: "Demarc. Limit"
            },
            {
              layer: fRe,
              title: "Rellenos"
            },
            {
              layer: fPe,
              title: "Pedreras"
            },
            {
              layer: fZonas,
              title: "Zonas Franja Costero Marina"
            },
            {
              layer: fAcuat,
              title: "Res. Acuat"
            },
            {
              layer: fPesca,
              title: "Pesca Art"
            },
            {
              layer: fCo,
              title: "Los Cóbanos"
            },
            {
              layer: golPrio,
              title: "Corredores biológicos prioritarios"
            },
            {
              layer: golCorre,
              title: "Corredores biológicos"
            },
            {
              layer: golMar,
              title: "Área Marina"
            },
            {
              layer: golRes,
              title: "Área reserva"
            },
            {
              layer: golPes,
              title: "Área pesca"
            },
            {
              layer: golBati,
              title: "Batimetría"
            },
            {
              layer: golMSPA,
              title: "MSPA"
            },
            {
              layer: golZAUS,
              title: "ZAUS"
            }
          ]
        })
      });

      view.ui.add(legend, "bottom-right");

      // Add the search widget to the top left corner of the view
      /*view.ui.add(searchWidget, {
        position: "top-right"
      });*/

      const sExpand = new Expand({
        view: view,
        expandIconClass: "esri-icon-search",
        content: searchWidget,
        expandTooltip: 'Buscar',
        collapseTooltip: 'Cerrar',
        label: 'Buscar'
      });

      view.ui.add(sExpand, 'top-right');

      //Realiza la busqueda especifica si el usuario selccionó un municipio en concreto en el home
      if (municipio) searchWidget.search(municipio);

      view.when(() => {
        // Create the LayerList widget with the associated actions
        // and add it to the top-right corner of the view.
        const layerList = new LayerList({
          view: view,
          // executes for each ListItem in the LayerList
          listItemCreatedFunction: defineActions
        });
        // Event listener that fires each time an action is triggered

        /**
         * 
         * @param {El nombre de la capa que se buscara} lNombre 
         */

        function switchDrive(lNombre) {
          switch (lNombre) {
            case 'Zonificación Golfo de Fonseca':
              window.open(DRIVE_DOCS + '1-p0RrUoDgfqI12MyfayXCI6bnIl11dmq');
              break;
            case 'Zonificación Franja Costero Marina':
              window.open(DRIVE_DOCS + '1lUYfMZee81UI5n17yGBp221Doyq1scWP');
              break;
            case 'Zonificación de Coatepeque':
              window.open(DRIVE_DOCS + '1O79J1zAmTVqnrUvyR7-5XM1cU6uL9Lsk');
              break;
            case 'Zonificación Nor Oriental':
              window.open(DRIVE_DOCS + '1MOoJguQ61CzfAtqypeOx0V94uaZHeW57');
              break;
            case 'Zonificación Nor Occidental':
              window.open(DRIVE_DOCS + '1LvOf6NNRej7E0GBkeIE2JnmwLV-hzjMj');
              break;
            case 'Zonificación Santo Tomás':
              window.open(DRIVE_DOCS + '1k0L0x1JpNBkplVKwXxrMP_VR5b_8G7Lw');
              break;
            case 'Zonificación Cordillera del Balsamo':
              window.open(DRIVE_DOCS + '1_U9mYWehz_LP2IIVmMw4adpa4ASOErRH');
              break;

            case 'Zonificación Nejapa':
              window.open(DRIVE_DOCS + '12LR6VgJtcL-2g0aVRncXXZVL1x3enBmw');
              break;

            case 'Zonificación Nuevo Cuscatlan':
              window.open(DRIVE_DOCS + '1gdWLyGwmpId8WyFGlkC2fP7YkrR-bk2s');
              break;

            case 'Zonificación Volcán de San Salvador':
              window.open(DRIVE_DOCS + '1gKnocUKdGJwpi_QCudixj9tP39eNzYYS');
              break;

            case 'Zonificación Antiguo Cuscatlán':
              window.open(DRIVE_DOCS + '1OCDswow4qwspuTUszI7Y9Vr7LYDE4_HK');
              break;
            default:
              break;
          }
        }

        /**
       * Función que gestiona la acción de redirección del botón descarga del popup
       * @param {array con los nombres de las layers que están en un estado visible en el popup} arrayLayer
       */
        function download(arrayLayer) {
          if (arrayLayer.length >= 2) {

            let question = window.confirm('Hay ' + arrayLayer.length + ' capas visibles por lo que se mostrarán '
              + arrayLayer.length + ' ventanas. Por favor asegúrate de que estás revisando la que es de tú interés');

            if (question === true) {

              arrayLayer.forEach(lNombre => {
                switchDrive(lNombre);
              });
            } else {
              window.alert('No se abrirá ninguna ventana, puede volver a intentarlo posteriormente.');
            }
          } else { switchDrive(arrayLayer[0]); }
        }

        layerList.on("trigger-action", (event) => {
          // The layer visible in the view at the time of the trigger.
          view.map.layers.forEach(gLayer => {
            if (gLayer.visible === true) {
              gLayer.layers.forEach(layer => {
                if (layer.visible === true) {

                  // Capture the action id.
                  const id = event.action.id;

                  if (id === "full-extent") {
                    // if the full-extent action is triggered then navigate
                    // to the full extent of the visible layer
                    view.goTo(layer.fullExtent).catch((error) => {
                      if (error.name !== "AbortError") {
                        console.error(error);
                      }
                    });
                  } else if (id === "information") {
                    view.map.layers.forEach(gLayer => {
                      if (gLayer.visible === true) {
                        download(gLayer.title);
                      }
                    });
                  } else if (id === "increase-opacity") {
                    // if the increase-opacity action is triggered, then
                    // increase the opacity of the GroupLayer by 0.25
                    if (gLayer.opacity < 1) gLayer.opacity += 0.25;

                  } else if (id === "decrease-opacity") {
                    // if the decrease-opacity action is triggered, then
                    // decrease the opacity of the GroupLayer by 0.25
                    if (gLayer.opacity > 0) gLayer.opacity -= 0.25;

                  }
                }
              });
            }
          });
        });

        // Event handler that fires each time an action is clicked.
        view.popup.on('trigger-action', event => {
          let dLayers = [];
          // Execute the download() function if the measure-this action is clibcked
          if (event.action.id === 'descargar') {
            view.map.layers.forEach(gLayer => {
              if (gLayer.visible === true) {
                dLayers.push(gLayer.title);
                /*
                if (gLayerFlag >= 2) {

                  window.confirm('Hay ' + gLayerFlag + ' capas visibles por lo que se mostrarán '
                    + gLayerFlag + ' ventanas. Por favor asegúrate de que estás revisando la que es de tú interés');
/*
                  if (question === true) {
                    download(gLayer.title);
                  } else {
                    window.alert('No se abrirá ninguna ventana, puede volver a intentarlo posteriormente.');
                  }
                } 
                console.log(gLayerFlag);
                download(gLayer.title);*/
              }
            });
            download(dLayers);
          }
        });

        var homeBtn = new Home({ view: view });

        view.ui.add(homeBtn, "top-left");

        // Add widget to the top right corner of the view
        //view.ui.add(layerList, "bottom-left");

        /**
         * Creating and adding the 'Expand' Widget for the layerList
         */
        const lLExpand = new Expand({
          view: view,
          expandIconClass: "esri-icon-layer-list",
          content: layerList,
          expandTooltip: 'Lista de Capas',
          collapseTooltip: 'Cerrar',
          label: 'Lista de capas',
        });

        view.ui.add(lLExpand, "top-left");
      });
    }
  });
  return (
    <div className="mapDiv" ref={mapDiv}></div>
  )
}

export default Map;