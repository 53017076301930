import downloadB from '../assets/imgs/download.png';

/**
 * 
 * @param {any} feature 
 * @returns el titulo en string de la layer seleccionada 
 */
function getLaTitle(feature: any): string {
    let graphic, layer;
    graphic = feature.graphic;
    layer = graphic.layer;
    return layer.title;
}

/**
 * @const downloadAction - Template para la acción de descargar de cada popup
 */
const downloadAction = {
    title: 'Descargar documentos',
    id: 'descargar',
    image: downloadB
};

/**
 * 
 * Configuración del popup template de cada una de las capas.
 * Se ha generalizado de forma en que se implemente en cada una dependediendo de su group layer.
 * Cada campo (fieldName) se ha obetenido de MapServer de VIGEA.
 * Los templates son:
 * @const popupAC - Antiguo Cuscatlan
 * @const popupVSS - Volcan de San Salvador
 * @const popupNC - Nuevo Cuscatlan
 */
export const popupAC = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'Shape'
                },
                {
                    fieldName: 'ZONAS_AMB',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'AREAS',
                    label: 'Areas'
                },
                {
                    fieldName: 'Proyecto'
                }
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupVSS = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'Name',
                    label: 'Nombre'
                },
                {
                    fieldName: 'Shape'
                },
                {
                    fieldName: 'zaus_zona1',
                    label: 'Zona Ambiental'
                }
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupNC = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'zona',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'Shape'
                },
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupNe = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'FIRST_FIRS',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'Name',
                    label: 'Nombre'
                },
                {
                    fieldName: 'Shape'
                },
                {
                    fieldName: 'BUFF_DIST'
                },
                {
                    fieldName: 'ORIG_FID'
                }
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupCord = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'zonas',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'Shape'
                },
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupSTo = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'FIRST_F201',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'Shape'
                },
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupOr = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'zona2018',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'Shape'
                },
                {
                    fieldName: 'NOM_MUN'
                }
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupOcc = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'zona2018',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'ZONA_03121',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'UNI_CONSER'
                },
                {
                    fieldName: 'NOMBRE',
                    label: 'Nombre'
                },
                {
                    fieldName: 'Zaus'
                },
                {
                    fieldName: 'CUENCA',
                    label: 'Cuenca'
                },
                {
                    fieldName: 'pZ_100918'
                },
                {
                    fieldName: 'Shape'
                },
                {
                    fieldName: 'NOM_MUN'
                }
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupCo = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'name',
                    label: 'Nombre'
                },
                {
                    fieldName: 'Amenaza'
                },
                {
                    fieldName: 'UNI_CONSER',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'ZAUS',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'Shape'
                },
                {
                    fieldName: 'NOM_MUN'
                }
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupF = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'UNI_CONSER',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'zona012018',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'zona',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'Zona',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'MATERIAL'
                },
                {
                    fieldName: 'NOMBRE',
                    label: 'Nombre'
                },
                {
                    fieldName: 'Shape'
                },
                {
                    fieldName: 'NOM_MUN'
                },
                {
                    fieldName: 'Descripcio',
                    label: 'Descripción'
                }
            ]
        }
    ],
    actions: [downloadAction]
}

export const popupGol = {
    title: getLaTitle,
    content: [
        {
            type: 'fields',
            fieldInfos: [
                {
                    fieldName: 'ZonaAmbien',
                    label: 'Zona Ambiental'
                },
                {
                    fieldName: 'ZMarina_AV',
                    label: 'Zona Ambiental' 
                },
                {
                    fieldName: 'MATERIAL'
                },
                {
                    fieldName: 'NOMBRE',
                    label: 'Nombre'
                },
                {
                    fieldName: 'Shape'
                }
            ]
        }
    ],
    actions: [downloadAction]
}