import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './routes/Home';
import Map from './routes/Map';
import NoPage from './routes/error/NoPage';
import './index.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Home />} /> 
          <Route path="mapa/:municipio" element={<Map />}/>
          <Route path="mapa/" element={<Map />}/>
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
//<Route path="mapa/:departamento/:municipio" element={<Map />}/>  
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);  