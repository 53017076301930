import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from '../constants/MuiThemes';
import NavBar from '../components/Navbar';
import CssBaseline from '@mui/material/CssBaseline';
import Form from "../components/Form";
import './style/Home.css';
import { Box } from '@mui/material';

function Home() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', 'flex-direction': 'column' }}>
        <NavBar />
        <Form />
      </Box>
    </ThemeProvider>
  );
}

export default Home;
